import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './ServiceContentSection.module.scss';
import ButtonLink from '../Buttons/ButtonLink';

interface Props {
  title: string;
  content: string;
  image: any;
  index: number;
}

const ServiceContent: FC<Props> = ({ title, content, image, index }) => {
  return (
    <section className={styles.serviceContentSection}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{index}. {title}</h2>
        <p className={styles.text}>
          {content}
        </p>
        {
          (title.match(/Treppenmeister/i)) && (
            <ButtonLink
              tab
              to="https://www.treppenmeister.com/fr/escalier-menuiserie-bordeau-mayenne-53/"
              text={"Voir plus de produits Treppenmeister"} />
          )
        }
      </div>

      <img
        className={styles.imageContainer}
        src={image.url}
        alt={title}
        loading='lazy'
      />
    </section>
  );
};

export default ServiceContent;