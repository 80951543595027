import { graphql, HeadFC } from "gatsby";
import * as React from "react";
import AboutUsSection from "../components/Sections/AboutUs";
import HomeSection from "../components/Sections/Home";
import ServicesSection from "../components/Sections/Services";
import WorksSection from "../components/Sections/Works";

const IndexPage = () => {
  return (
    <main>
      <HomeSection />
      {/* <ServicesSection /> */}
      <WorksSection />
      <AboutUsSection />
    </main>
  );
};

export const Head: HeadFC = ({ data }: any) => {
  const { general } = data;

  return (
    <>
      <title>{general.title}</title>
      <meta name="description" content={general.description?.slice(0, 150)} />
    </>
  );
};

export const query = graphql`
  {
    general: sanityGeneral {
      title
      description
    }
  }
`;


export default IndexPage;
