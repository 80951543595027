// extracted by mini-css-extract-plugin
export var description = "Home-module--description--00247";
export var excerpt = "Home-module--excerpt--d9244";
export var home = "Home-module--home--6e269";
export var image = "Home-module--image--296b2";
export var infoContainer = "Home-module--infoContainer--dc0c2";
export var leftContainer = "Home-module--leftContainer--11838";
export var logo = "Home-module--logo--50b68";
export var service = "Home-module--service--f5dd6";
export var servicesContainer = "Home-module--servicesContainer--0bfaf";
export var title = "Home-module--title--539ef";