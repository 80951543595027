import React, { FC } from 'react';
import { Link } from 'gatsby';

import * as styles from './Button.module.scss';

interface Props {
    text: string;
    to: string;
    light?: boolean;
    tab?: boolean;
}

const ButtonLink: FC<Props> = ({ text, to, light, tab }) => {
    return (
        <Link to={to} target={tab ? '_blank' : ''} className={`${styles.button} ${light ? styles.light : undefined}`}>
            {text}
        </Link>
    );
};

export default ButtonLink;