import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import ButtonLink from '../Buttons/ButtonLink';
import SectionTitle from '../Titles/SectionTitle';
import * as styles from './Works.module.scss';

const WorksSection: FC = () => {

    const { projects } = useStaticQuery(graphql`
        {
            projects: allSanityProject(limit: 8, sort: {fields: _createdAt, order: DESC}) {
                edges {
                    node {
                        images {
                            asset {
                                id
                                # image should be contained in a 300px square
                                gatsbyImageData(width:300, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                                url
                                width
                                height
                            }
                        }
                    }
                }
            }
        }
    `);

    const images = projects.edges.map((project: any) => project.node.images[0]);

    const columns: {
        images: any[];
        height: number;
    }[] = [
            {
                images: [],
                height: 0,
            },
            {
                images: [],
                height: 0,
            },
            {
                images: [],
                height: 0,
            }
        ];

    images
    .filter((image: any) => image.asset)
    .forEach((image: any, index: number) => {
        const smallestColumn = columns.reduce((acc, column, index) => {
            if (column.height < acc.height) {
                return column;
            }

            return acc;
        }, columns[0]);

        smallestColumn.images.push(image);
        smallestColumn.height += image.asset?.height;
    });

    return (
        <section className={styles.worksSection}>
            <SectionTitle title="Nos dernières créations" />

            <div className={styles.worksContainer}>
                {
                    // @ts-ignore
                    columns.map((column: any[], index: number) => (
                        <div className={styles.worksColumn} key={index}>
                            {
                                // @ts-ignore
                                column.images.map((image: any) => (
                                    <div className={styles.worksItem} key={image.asset.id}>
                                        <img
                                            alt={image.asset.altText || image.asset.id}
                                            src={image.asset.url}
                                            loading='lazy'
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>

            <ButtonLink to="/projets" text={'Découvrir toutes nos créations'} light />
        </section>
    );
};

export default WorksSection;