import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";
import ServiceContentSection from "../Services/ServiceContentSection";
import SectionTitle from "../Titles/SectionTitle";
import * as styles from './AboutUs.module.scss';

const AboutUsSection: FC = () => {

    const { about } = useStaticQuery(graphql`
        {
            about: sanityAbout {
                title
                sections {
                    _key
                    _type
                    title
                    content
                    image {
                        asset {
                            gatsbyImage(width: 500)
                            altText
                            id
                            url
                        }
                    }
                }
            }
        }
    `);

    return (
        <section className={styles.aboutUs} id="about-us">
            <SectionTitle title={about.title} />

            {
                about.sections.map((section: any, index: number) => (
                    <ServiceContentSection
                        content={section.content}
                        image={section.image.asset}
                        key={section._key}
                        title={section.title}
                        index={index + 1}
                    />
                ))
            }
        </section>
    );
};

export default AboutUsSection;