import React, { FC } from 'react'

import * as styles from './SectionTitle.module.scss'

interface Props {
    title: string
    light?: boolean
}

const SectionTitle: FC<Props> = ({ title, light }) => {
    return (
        <h2 className={`${styles.title} ${light ? styles.light : undefined}`}>
            {title}
        </h2>
    )
}

export default SectionTitle