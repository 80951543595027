import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import Button from '../Buttons/Button';
import ButtonLink from '../Buttons/ButtonLink';
import * as styles from './Home.module.scss';

const HomeSection: FC = () => {

  const { general, services } = useStaticQuery(graphql`
      {
        general: sanityGeneral {
          title
          description
          logo {
            asset {
              url
            }
          }
          homeBackground {
            asset {
              publicUrl
            }
          }
        }

        services: allSanityService {
          edges {
            node {
              _id
              slug {
                current
              }
              name
              excerpt (limit: 70)
              # icon {
              #   _key
              #   _type
              #   provider
              #   name
              # }
              iconName
              image {
                asset {
                  gatsbyImageData(width: 400)
                }
              }
            }
          }
        }
      }
    `);


  return (
    <>
      <section className={styles.home} style={{ backgroundImage: `linear-gradient(112.42deg, rgba(103, 136, 55, 0.1) 0%, rgba(69, 88, 66, 0.1) 100.32%), url(${general.homeBackground.asset.publicUrl})` }}>
        <img
          src={general.logo.asset.url}
          className={styles.logo}
          alt="logo"
        />

        <p className={styles.description}>
          {general.description}
        </p>

        <div className={styles.servicesContainer}>
          {
            services.edges.map(({ node: service }: any) => {
              return (
                <Link to={`services/${service.slug.current}`} className={styles.service} key={service.slug.current}>
                  {
                    service.image && (
                      <GatsbyImage
                        className={styles.image}
                        objectFit="cover"
                        // @ts-ignore
                        image={getImage(service.image.asset.gatsbyImageData)}
                        alt={service.name} />
                    )
                  }
                  <div className={styles.infoContainer}>
                    <i className={`fa fa-${service?.iconName}`}></i>
                    <h3>{service.name}</h3>
                    <p className={styles.excerpt}>
                      {service.excerpt}
                    </p>
                    <Button text={`Découvrir`} />
                  </div>
                </Link>
              );
            })
          }
        </div>
      </section>
    </>
  );
};

export default HomeSection;